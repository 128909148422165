import { createStore, compose } from 'redux'
import { parseJson } from 'src/libs/utils'
import Cookies from 'js-cookie'
import { Action, State } from './types'

const initUser = {
  name: '',
  acceptedTerms: false,
  pollCode: '',
  acceptedOverwriteEarlyVote: false
}

const initIdentification = {
  certificateData: null,
  cognito: null,
  documentIdLabel: '',
  identificationType: null
}

export const initState: State = {
  votingType: (sessionStorage.getItem('votingType') ?? 'normal') as State['votingType'],
  loaders: 0,
  setLoaders: () => {},
  notification: {
    open: false,
    severity: 'info',
    message: ''
  },
  bearerToken: Cookies.get('token') ?? '',
  user: parseJson<State['user']>(Cookies.get('user') || '') || initUser,
  agent: null,
  poll: undefined,
  census: undefined,
  pollStatus: 'waiting',
  activeQuestion: undefined,
  pollFiles: [],
  domainTheme: undefined,
  active_plugins: [],
  zoomCredentials: {
    client_id: '',
    meeting_number: '',
    meeting_password: '',
    token: '',
    active: false,
    type: 'ZOOM'
  },
  remoteLinkConfig: null,
  identification: initIdentification
}

export const reducer = (state = initState, action: Action): State => {
  if (action.type == 'ADD-LOADER') {
    return {
      ...state,
      loaders: state.loaders + 1
    }
  } else if (action.type == 'REMOVE-LOADER') {
    return {
      ...state,
      loaders: Math.max(0, state.loaders - 1)
    }
  } else if (action.type == 'SET-NOTIFICATION') {
    const payload = action.payload
    return {
      ...state,
      notification: {
        ...state.notification,
        ...payload
      }
    }
  } else if (action.type == 'CLOSE-NOTIFICATION') {
    return {
      ...state,
      notification: {
        ...state.notification,
        open: false
      }
    }
  }
  if (action.type == 'SET-BEARER-TOKEN') {
    const payload = action.payload
    return {
      ...state,
      ...payload
    }
  } else if (action.type == 'SET-USER') {
    const payload = action.payload
    return {
      ...state,
      user: {
        ...state.user,
        ...payload
      }
    }
  } else if (action.type == 'SET-POLL') {
    const payload = action.payload
    return {
      ...state,
      poll: payload
    }
  } else if (action.type == 'SET-POLL-STATUS') {
    const payload = action.payload
    return {
      ...state,
      pollStatus: payload
    }
  } else if (action.type == 'SET-ACTIVE-QUESTION') {
    const payload = action.payload
    return {
      ...state,
      activeQuestion: payload
    }
  } else if (action.type == 'SET-AGENT') {
    const payload = action.payload
    return {
      ...state,
      agent: payload
    }
  } else if (action.type == 'SET-VOTING-TYPE') {
    const payload = action.payload
    return {
      ...state,
      votingType: payload
    }
  } else if (action.type == 'SET-CENSUS') {
    const payload = action.payload
    return {
      ...state,
      census: payload
    }
  } else if (action.type == 'SET-POLL-FILES') {
    const payload = action.payload
    return {
      ...state,
      pollFiles: payload
    }
  } else if (action.type == 'SET-DOMAIN-THEME') {
    const payload = action.payload
    return {
      ...state,
      domainTheme: payload
    }
  } else if (action.type == 'SET-ZOOM-CREDENTIALS') {
    const payload = action.payload
    return {
      ...state,
      zoomCredentials: payload
    }
  } else if (action.type == 'SET-REMOTE-LINK-CONFIG') {
    const payload = action.payload
    return {
      ...state,
      remoteLinkConfig: payload
    }
  } else if (action.type == 'SET-IDENTIFICATION') {
    const payload = action.payload
    return {
      ...state,
      identification: {
        ...state.identification,
        ...payload
      }
    }
  } else if (action.type == 'CLEAR-STATE') {
    localStorage.clear()
    sessionStorage.clear()
    Cookies.remove('token')
    Cookies.remove('user')
    return {
      ...initState,
      bearerToken: '',
      user: initUser,
      domainTheme: state.domainTheme
    }
  }

  return state
}

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export default createStore(reducer, composeEnhancers())

import axios, { AxiosInstance } from 'axios'
import { ADMIN_API } from './constants'
import { Identification } from './types'

export const getIdentification = async (params: { pollCode: string }): Promise<Identification> => {
  const { pollCode } = params
  const res = await axios.get(`${ADMIN_API}/poll/${pollCode}/identification`)
  return res.data
}

type GetTokenParams = {
  pollCode: string
  adminToken?: string
}
export const getToken = async (http: AxiosInstance, params: GetTokenParams) => {
  const { pollCode, adminToken } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/token`, {
    params: { adminToken }
  })
  return res.data
}

export const getCertificate = async (params: { certificateUrl: string }): Promise<string> => {
  const { certificateUrl } = params
  const res = await axios.get(certificateUrl)
  return res.data
}
